<template>
  <v-card >
    <v-card-text>
      <div class="joined">
        <v-icon size="80" color="primary"> mdi-check-circle </v-icon>
        <h3>Successfully joined.</h3>
      </div>

      <v-divider></v-divider>

      <div class="redirect">We'll redirect you in {{ time }} seconds.</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    time: Number,
  }
}
</script>

<style lang="css" scoped>
.joined {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.redirect {
  text-align: center;
  padding: 10px 0px;
}
</style>
