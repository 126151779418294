<template>
  <v-card >
    <v-card-text class="wait">
      <h2>Please wait...</h2>
      <span>We are joining your profile to an account.</span>
      <v-progress-circular
      indeterminate
      color="primary"
      size="50"
      ></v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style lang="css" scoped>
  .wait{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
</style>
