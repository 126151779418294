<template>
  <v-container class="d-flex flex-column pa-8">
    <join-wait v-if="loading" />

    <join-redirect v-else-if="joined" :time="time" />

    <div v-else>
      <div class="header-top">
        <h2 class="view-title">Join an account</h2>
      </div>
      <v-card>
        <v-card-text>
          <v-form ref="form" class="form-report">
            <h2 class="code">Join Code</h2>
            <v-text-field
              v-model="code"
              label="Code"
              outlined
              placeholder="Code"
              hint="Join Code"
              persistent-hint
              hide-details="auto"
              :rules="[validator.required]"
            />

            <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loading">
              <span v-if="loading === false">Join</span>
              <span v-else>
                <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
                <span>Sending request...</span>
              </span>
            </v-btn>
          </v-form>

          <v-alert v-if="errors" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>


<script>
import { mapActions } from 'vuex'
import { required, urlValidator } from '@core/utils/validation'
import JoinWait from './components/JoinWait.vue'
import JoinRedirect from './components/JoinRedirect.vue'

export default {
  components: {
    JoinWait,
    JoinRedirect,
  },
  data() {
    return {
      loading: false,
      auto: false,
      joined: false,
      time: 3,
      count: 5,
      code: '',
      errors: null,
      validator: {
        required,
        urlValidator,
      },
    }
  },
  created() {
    if (this.$route.query.code) {
      this.loading = true
      this.code = this.$route.query.code
      this.requestJoin(this.code)
    } else {
      this.auto = false
    }
  },
  methods: {
    ...mapActions({
      join: 'invitations/join',
      setSnackbar: 'snackbar/set',
    }),
    goToPath(path) {
      this.$router.push({ path })
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.requestJoin(this.code)
      }
    },
    async requestJoin(code) {
      const formData = {
        code: code.trim(),
      }

      await this.join(formData)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              this.setSnackbar({
                status: true,
                text: 'You have joined an account.',
                color: 'primary',
              })

              this.errors = null
              this.redirectView()
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          }

          this.setSnackbar({
            status: true,
            text: 'Failed to join account.',
            color: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async redirectView() {
      this.joined = true
      this.time = 3

      const updateClock = () => {
        if (this.time > 0) {
          this.time -= 1
          setTimeout(updateClock, 1000)
        } else {
          window.location = process.env.VUE_APP_FRONTEND_URL
        }
      }

      updateClock()
    },
  },
}
</script>

<style scoped>
.code {
  margin: 10px 0px;
}
.header-top {
  margin-bottom: 20px;
}
.btn-more {
  padding: 0px !important;
  word-break: break-word !important;
}
.btn-submit {
  margin-top: 20px;
}
</style>
